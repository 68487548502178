import gsap from "gsap";

const toggle = document.querySelector(".menu-toggle");
const toggleSpan = document.querySelectorAll(".menu-toggle span");
const menu = document.querySelector(".menu");
const navItem = menu.querySelectorAll(".menu-nav__item");
const navLink = menu.querySelectorAll(".menu-nav__link");
const menuLogo = menu.querySelector(".menu-logo");
const body = document.body;

const tlMenu = gsap.timeline({
	paused: true,
	reversed: true,

	defaults: {
		ease: "power3.out",
	},
});

gsap.set(menu, { left: "120%" });

tlMenu
	.to(menu, {
		left: 0,
	})
	.to(toggle, { rotation: 360, backgroundColor: "var(--toggle-btn-current)" }, 0)
	.to(toggleSpan, { backgroundColor: "var(--toggle-icon-current)" }, 0)
	.to(toggleSpan[0], { rotate: 45, y: "-50%", x: "0" }, 0)
	.to(toggleSpan[1], { opacity: 0 }, 0)
	.to(toggleSpan[2], { rotate: -45, y: "-50%", x: "0" }, 0)
	.to(menuLogo, { opacity: 1 }, 0)
	.to(
		navItem,
		{
			opacity: 1,
			left: 0,
			stagger: {
				each: 0.02,
			},
		},
		">-0.5"
	);

const menuToggleOpen = () => {
	setTimeout(() => {
		toggle.setAttribute("aria-expanded", "true");
		tlMenu.play();
		body.style.overflowY = "hidden";
	}, 1);
};

export const menuToggleClose = () => {
	setTimeout(() => {
		toggle.setAttribute("aria-expanded", "false");
		tlMenu.reverse();
		body.style.overflowY = "initial";
	}, 1);
};

export const menuToggleInstant = () => {
	toggle.setAttribute("aria-expanded", "false");
	tlMenu.restart();
	tlMenu.pause();
};

export const menuToggle = () => {
	toggle.addEventListener("click", () => {
		// if (!tlMenu.isActive()) {
		// 	tlMenu.reversed(!tlMenu.reversed());
		// }

		if (toggle.getAttribute("aria-expanded") == "false") {
			menuToggleOpen();
		} else {
			menuToggleClose();
		}
	});
};

export const navState = () => {
	navLink.forEach((link) => {
		link.removeAttribute("aria-current", "page");

		if (link.href.split("/").pop().split("#").shift() === window.location.href.split("/").pop().split("#").shift()) {
			link.setAttribute("aria-current", "page");
		}

		if (window.location.href.split("/").pop() === "") {
			document.querySelectorAll(".menu-nav__link")[0].setAttribute("aria-current", "page");
		}
	});
};

//Menu anchor scroll function
window.anchorScroll = function (x) {
	menuToggleClose();

	setTimeout(function () {
		gsap.to(window, {
			duration: 1,
			scrollTo: {
				y: "#" + x,
			},
		});
	}, 1000);
}
